import request from "./request";

export function getCheckList(params) {
    return request({
        url: `/manager/battleCheck/query`,
        method: 'get',
        params:params
    })
}

export function getResult(gameId) {
    return request({
        url: `/manager/battleCheck/queryAll`,
        method: 'get',
        params:{gameId:gameId}
    })
}

export function getBattleList(params) {
    return request({
        url: `/manager/battle/query`,
        method: 'get',
        params:params
    })
}

export function startAll(gameId) {
    return request({
        url: `/manager/game/startAll/${gameId}`,
        method: 'get'
    })
}

export function startCurrent(battleId) {
    return request({
        url: `/manager/battle/startCurrent/${battleId}`,
        method: 'get',
        params
    })
}


export function redWin(battleId) {
    return request({
        url: `/manager/battle/redWin/${battleId}`,
        method: 'get'
    })
}


export function blueWin(battleId) {
    return request({
        url: `/manager/battle/blueWin/${battleId}`,
        method: 'get'
    })
}


export function draw(battleId) {
    return request({
        url: `/manager/battle/draw/${battleId}`,
        method: 'get'
    })
}

export function getStatusAndIndex(battleId) {
    return request({
        url: `/manager/game/getStatusAndIndex/${battleId}`,
        method: 'get'
    })
}

export function pass(params) {
    return request({
        url: `/manager/battleCheck/pass`,
        method: 'get',
        params:params
    })
}

export function fail(params) {
    return request({
        url: `/manager/battleCheck/fail`,
        method: 'get',
        params:params
    })
}