const origin='http://broadcast.xmindtech.com'
// const origin='http://192.168.0.27:8080'
// const origin='http://localhost:8080'
export const imgPreloaderList = [
  require('../assets/images/battle_info.png'),
  require('../assets/images/blue.png'),
  require('../assets/images/blue_shadow.png'),
  require('../assets/images/blue_win.gif'),
  require('../assets/images/red_win.gif'),
  require('../assets/images/draw.gif'),
  require('../assets/images/red.png'),
  require('../assets/images/red_shadow.png'),
  require('../assets/images/result.png'),
];