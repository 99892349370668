// 引入vue
import Vue from 'vue';
// 引入vue-router
import VueRouter from 'vue-router';
// 注册 第三方库需要use一下才能用
Vue.use(VueRouter)

import Broadcast from '../views/Broadcast.vue'

// 定义routes路由的集合，数组类型
const routes=[
    {path:"/broadcast",component:Broadcast}
]

// 实例化VueRouter并将routes添加进去
const router = new VueRouter({
// ES6简写，等于routes：routes
    routes
});

// 抛出这个这个实例对象方便外部读取以及访问
export default router
