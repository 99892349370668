<template>
  <div id="app">
    <!-- 路由匹配到的组件将渲染在这里 -->
    <router-view></router-view>
  </div>
</template>
 
<script>
export default {
  name: "App",
  components: {},

  data() {
    return {};
  },

  mounted() {

  },

};
</script>
 
<style>
#app {
  /*文字居中*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  font-family:"Noto Sans CJK SC", "Source Han Sans CN";
}

* {
  /*初始化样式*/
  margin: 0;
  padding: 0;
}

html {
  /*用于 获取 屏幕的可视宽高*/
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  /*让 body 初始 width 和 height 就 等于 页面可视区域的 宽高*/
  /* position: fixed;
  left: 0;
  top: 0; */
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

</style>