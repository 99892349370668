import Vue from 'vue'
import App from './App.vue'
import router from './router'
// 阻止vue在启动时生成的生产提示
Vue.config.productionTip = false
import '../src/assets/css/font.css';
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css' //样式表
Vue.use(ElementUI); //使用element-ui
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)

Vue.filter('numFilter', value => {
  let num = value.toString()
  while (num.length < 3) {
    num = '0' + num
  }
  return num
})

// 导入图片预加载方法以及图片列表
import {
  preloader
} from './preload/imgPreloader.js';
import {
  imgPreloaderList
} from './preload/imgPreloaderList.js';

(async () => {

  await preloader(imgPreloaderList)
  
  new Vue({
    router,
    render: h => h(App)
  }).$mount('#app');
})();