// const videoPreloader = url => {
//     return new Promise((resolve, reject) => {
//         let video = document.createElement('video')
//         // 自动播放
//         video.setAttribute("preload", 'auto')
//         // 静音
//         video.setAttribute("muted", 'muted')
//         video.setAttribute("src", url)
//         video.setAttribute("autoplay", "autoplay")
//         video.setAttribute("width", 0)
//         video.setAttribute("height", 0)
//         // video.style.display='none'
//         // 为了保险 我再加个volume = 0  volume就是音量
//         video.volume = 0
//         document.querySelector('.loadingWrapper').appendChild(video);
//         video.onended= () => {
//             video.remove()
//             resolve();
//         };
//         video.onerror = () => {
//             video.remove()
//             reject();
//         };
//     });
// };
const videoPreloader = data => {
    return new Promise(function (resolve, reject) {
        var req = new XMLHttpRequest();
        req.open('GET', data.url, true);
        req.responseType = 'blob';
        req.onload = function () {
            //即使是404也会进入这个相应函数，所以需要检测状态
            if (req.status == 200) {
                const videoBlob = this.response;
                const blobSrc = URL.createObjectURL(videoBlob); // IE10+
                // Video is now downloaded
                // and we can set it as source on the video element
                // video.src = blobSrc ;
                console.log(blobSrc, 'blobSrc加载完毕');
                var video = document.querySelector('#' + data.id)
                console.log(video)
                video.src = blobSrc
                //完成许诺，返回响应文本
                resolve(req.response);
            } else {
                //完成未完成，返回错误
                reject(Error(req.statusText));
            }
        };

        // 发生错误时的相应函数
        req.onerror = function () {
            reject(Error("Network Error"));
        };
        // 发送请求
        req.send();
    });
};
export const preloader = videos => {
    let promiseArr = [];
    videos.forEach(element => {
        promiseArr.push(videoPreloader(element));
    });
    return Promise.all(promiseArr);
};