<template>
  <div id="broadcast" v-loading="loading">
    <div style="width: 100%; height: 100%; position: absolute" v-show="video1">
      <video
        id="video1"
        muted
        width="1920"
        height="1080"
        style="
          mix-blend-mode: screen;
          object-fit: cover;
          position: absolute;
          left: 0;
          right: 0;
          top: 5%;
          bottom: 0;
          margin: auto;
          z-index: 10;
        "
      ></video>
    </div>
    <div
      class="win_shadow"
      style="
        width: 1920px;
        height: 1080px;
        background-color: rgba(0, 0, 0, 0.75);
      "
      v-if="redWin"
    >
      <img
        id="red_win"
        style="
          width: 1080px;
          height: 1080px;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          margin: auto;
          z-index: 10;
        "
        src="../assets/images/red_win.gif"
      />
    </div>
    <div
      class="win_shadow"
      style="
        width: 1920px;
        height: 1080px;
        background-color: rgba(0, 0, 0, 0.75);
      "
      v-if="draw"
    >
      <img
        id="draw"
        style="
          width: 1080px;
          height: 1080px;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          margin: auto;
          z-index: 10;
        "
        src="../assets/images/draw.gif"
      />
    </div>
    <div
      class="win_shadow"
      style="
        width: 1920px;
        height: 1080px;
        background-color: rgba(0, 0, 0, 0.75);
      "
      v-if="blueWin"
    >
      <img
        id="blue_win"
        style="
          width: 1080px;
          height: 1080px;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          margin: auto;
          z-index: 10;
        "
        src="../assets/images/blue_win.gif"
      />
    </div>
    <div class="top_info" v-if="topInfo">
      <img class="icon1" src="../assets/images/icon1.png" />
      <div class="next" v-if="index != arr.length - 1">
        下一场比赛：
        <span v-if="index != arr.length - 1"
          >{{ arr[index + 1].redName }}
          <div class="split">VS</div>
          {{ arr[index + 1].blueName }}</span
        >
      </div>
    </div>
    <div class="battle_info" v-if="battleInfo">
      <img class="redAvatar" :src="arr[index].redAvatar" alt="" />
      <img class="blueAvatar" :src="arr[index].blueAvatar" alt="" />
      <div class="redName">{{ arr[index].redName }}</div>
      <div class="blueName">{{ arr[index].blueName }}</div>
      <div class="current">
        {{ index + 1 }}
        <div class="split">/</div>
        {{ arr.length }}
      </div>
    </div>
    <div class="ready">
      <transition name="red">
        <div class="red" v-if="ready">
          <img class="main" src="../assets/images/red.png" alt="" />
          <img class="logo" :src="arr[index].redAvatar" alt="" />
          <div class="name">{{ arr[index].redName }}</div>
          <img
            class="shadow"
            :style="{ opacity }"
            src="../assets/images/red_shadow.png"
            alt=""
          />
        </div>
      </transition>
      <transition name="blue">
        <div class="blue" v-if="ready">
          <img class="main" src="../assets/images/blue.png" alt="" />
          <img class="logo" :src="arr[index].blueAvatar" alt="" />
          <div class="name">{{ arr[index].blueName }}</div>
          <img
            class="shadow"
            :style="{ opacity }"
            src="../assets/images/blue_shadow.png"
            alt=""
          />
        </div>
      </transition>
    </div>
    <div class="result" v-if="result">
      <div class="topFive">
        <ul class="item">
          <li v-for="(item, index) in arr1" :key="index">
            <span class="index">{{index+1}}</span>
            <span class="name" v-text="item.teamName"></span>
            <span class="win" v-text="item.win"></span>
            <span class="lose" v-text="item.lose"></span>
            <span class="draw" v-text="item.draw"></span>
            <span class="total" v-text="item.total"></span>
          </li>
        </ul>
      </div>
      <vue-seamless-scroll :data="arr2" :class-option="classOption" class="wrap">
        <ul class="item">
          <li v-for="(item, index) in arr2" :key="index">
            <span class="index">{{index+6}}</span>
            <span class="name" v-text="item.teamName"></span>
            <span class="win" v-text="item.win"></span>
            <span class="lose" v-text="item.lose"></span>
            <span class="draw" v-text="item.draw"></span>
            <span class="total" v-text="item.total"></span>
          </li>
        </ul>
      </vue-seamless-scroll>
    </div>
  </div>
</template>
<script>
// 导入图片预加载方法以及图片列表
import { preloader } from "../preload/videoPreloader.js";
import { videoPreloaderList } from "../preload/videoPreloaderList.js";
import * as api from "../api/api";
export default {
  data() {
    return {
      battleInfo: false,
      topInfo: false,
      flag: false, //中途刷新
      gameId: "",
      indexMap: {},
      per: 0.01,
      video1: false,
      redWin: false,
      blueWin: false,
      draw: false,
      ready: false,
      result: false,
      // loading: true,
      loading: false,
      status: "0",
      index: 0,
      opacity: 1,
      timer: undefined,
      allTimer: undefined,
      tableState: false,
      arr: [],
      arr1: [],
      arr2: [],
      classOption: {
        // direction: 0,
        limitMoveNum: 8,
      },
    };
  },

  methods: {
    getStatusAndIndex() {
      api.getStatusAndIndex(this.gameId).then((res) => {
        let data = res.data.result;
        //当比赛启动
        if (this.status == "0" && data.status == "1") {
          this.status = "1";
          this.close();
        } else if (this.status == "1" && data.status == "1") {
          //当比赛进行中
          //当场次切换
          if (data.currentIndex != this.index) {
            this.index = data.currentIndex;
            //执行胜负动画
            if (data.lastWinner == "red") {
              this.redWin = true;
              this.battleInfo = false;
              this.topInfo = false;
              setTimeout(() => {
                this.getBattleList()
                this.redWin = false;
              }, 6000);
            }
            if (data.lastWinner == "blue") {
              this.blueWin = true;
              this.battleInfo = false;
              this.topInfo = false;
              setTimeout(() => {
                this.getBattleList()
                this.blueWin = false;
              }, 6000);
            }
            if (data.lastWinner == "draw") {
              this.draw = true;
              this.battleInfo = false;
              this.topInfo = false;
              setTimeout(() => {
                this.draw = false;
              }, 6000);
            }
            //执行对局闭合动画
            // this.ready = true;
            // this.battleInfo = false;
            // this.topInfo = false;
            // setTimeout(() => {
            //   this.change();
            //   var videoElement = document.getElementById("video1");
            //   this.video1 = true;
            //   videoElement.load();
            //   videoElement.play();
            //   setTimeout(() => {
            //     this.video1 = false;
            //   }, 2670);
            // }, 1000);
          } else if (data.currentIndex == this.index) {
            //如果当前对局开始
            if (data.currentStatus == "ready") {
              if (!(this.redWin || this.blueWin || this.draw)) {
                //执行对局打开动画
                if (this.ready) {
                  this.ready = false;
                  clearInterval(this.timer);
                  this.opacity = 1;
                  setTimeout(() => {
                    this.battleInfo = true;
                    this.topInfo = true;
                  }, 3000);
                } else {
                  this.battleInfo = true;
                  this.topInfo = true;
                }
              }
            } else {
              if (!this.ready) {
                if (!(this.redWin || this.blueWin || this.draw)) {
                  this.close();
                }
              }
            }
          }
        } else if (data.status == "2") {
          if (
            this.status == "1" &&
            !(this.redWin || this.blueWin || this.draw)
          ) {
            //执行胜负动画
            if (data.lastWinner == "red") {
              this.redWin = true;
              this.battleInfo = false;
              this.topInfo = false;
              setTimeout(() => {
                this.redWin = false;
                this.status="2"
              }, 6000);
            }
            if (data.lastWinner == "blue") {
              this.blueWin = true;
              this.battleInfo = false;
              this.topInfo = false;
              setTimeout(() => {
                this.blueWin = false;
                this.status="2"
              }, 6000);
            }
            if (data.lastWinner == "draw") {
              this.draw = true;
              this.battleInfo = false;
              this.topInfo = false;
              setTimeout(() => {
                this.draw = false;
                this.status="2"
              }, 6000);
            }
          }
          if (!(this.redWin || this.blueWin || this.draw)) {
            clearInterval(this.allTimer);
            this.battleInfo = false;
            this.topInfo = false;
            this.getResult()
            //打开排名表
            // this.result = true;
          }
        }
      });
    },
    close() {
      this.ready = true;
      this.battleInfo = false;
      this.topInfo = false;
      setTimeout(() => {
        this.change();
        var videoElement = document.getElementById("video1");
        this.video1 = true;
        videoElement.load();
        videoElement.play();
        setTimeout(() => {
          this.video1 = false;
        }, 2670);
      }, 1000);
    },
    getBattleList() {
      let query = { pageNumber: 1, pageSize: 100, gameId: this.gameId };
      api.getBattleList(query).then((res) => {
        this.arr = res.data.result;
      });
    },
    getResult() {
      api.getResult(this.gameId).then((res) => {
        console.log(res)
        this.arr1=res.data.result.splice(0,5)
        this.arr2=res.data.result
        console.log(this.arr1)
        console.log(this.arr2)
      });
    },
    change() {
      this.timer = setInterval(() => {
        this.opacity -= this.per; //透明度减0.01
        if (this.opacity <= 0) {
          this.opacity = 0.01;
          this.per = -this.per; //当透明度为0就重新设为1
        } else if (this.opacity >= 1) {
          this.opacity = 0.99;
          this.per = -this.per;
        }
      }, 10);
    },
    sleep(ms) {
      //sleep延迟方法2
      var unixtime_ms = new Date().getTime();
      while (new Date().getTime() < unixtime_ms + ms) {}
    },
  },
  created() {
    this.gameId = this.$route.query.id;
    this.getBattleList();
  },
  mounted() {
    preloader(videoPreloaderList).finally(() => {
      window.NProgress.done();
      //关闭加载弹框
      this.loading = false;
    });
    api.getStatusAndIndex(this.gameId).then((res) => {
      let data = res.data.result;
      if (data.status == "1") {
        this.status = data.status;
        this.index = data.currentIndex;
        if (data.currentStatus != "ready") {
          this.ready = true;
          this.battleInfo = false;
          this.topInfo = false;
          setTimeout(() => {
            this.change();
            var videoElement = document.getElementById("video1");
            this.video1 = true;
            videoElement.load();
            videoElement.play();
            setTimeout(() => {
              this.video1 = false;
            }, 2670);
          }, 1000);
        }
        this.flag = true;
      }
      if (data.status == "2") {
        this.status == "2";
      }
    });
    this.allTimer = setInterval(() => {
      this.getStatusAndIndex();
    }, 1000);
    this.$once("hook:beforeDestroy", () => {
      clearInterval(this.allTimer);
    });
  },
};
</script>
<style scoped>
#broadcast {
  width: 100%;
  height: 100%;
  margin: auto;
}
.top_info {
  position: absolute;
  width: 1920px;
  height: 30px;
  top: 0;
  z-index: 10;
  background: #515151;
}
.top_info .icon1 {
  position: absolute;
  width: 10px;
  height: 15px;
  left: 20px;
  top: 7px;
  /* object-fit: cover; */
}
.top_info .next {
  position: absolute;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  left: 50px;
  color: #fff;
}
.next .split {
  display: inline-block;
  width: 40px;
}
.battle_info {
  position: absolute;
  width: 1920px;
  height: 116px;
  bottom: -2px;
  z-index: 3;
  background: url("../assets/images/battle_info.png") no-repeat;
  background-size: 100% 100%;
}

.ready {
  position: absolute;
  top: 0;
}
.ready .red {
  position: absolute;
  width: 1920px;
  height: 1080px;
}
.ready .red .main {
  position: absolute;
  width: 1920px;
  height: 1080px;
  left: 0;
  object-fit: cover;
}
.ready .red .shadow {
  position: absolute;
  width: 928px;
  height: 1080px;
  object-fit: cover;
  left: 0;
}
.ready .red .logo {
  position: absolute;
  left: 7%;
  top: 35%;
  width: 400px;
  height: 400px;
  z-index: 10;
}
.ready .red .name {
  position: absolute;
  left: 7%;
  top: 27%;
  width: 400px;
  height: 60px;
  font-size: 50px;
  color: #fff;
  z-index: 10;
}
.red-enter {
  transform: translateX(-100%);
}
.red-enter-active {
  transition: all 1s ease;
}
.red-enter-to {
  transform: translateX(0);
}
.red-leave {
  transform: translateX(0);
}
.red-leave-active {
  transition: all 3s ease;
}
.red-leave-to {
  transform: translateX(-100%);
}
.ready .blue {
  position: absolute;
  width: 1920px;
  height: 1080px;
}
.ready .blue .main {
  position: absolute;
  width: 1920px;
  height: 1080px;
  right: 0;
  object-fit: cover;
}
.ready .blue .shadow {
  position: absolute;
  width: 928px;
  height: 1080px;
  object-fit: cover;
  right: 0;
}
.ready .blue .logo {
  position: absolute;
  right: 7%;
  top: 35%;
  width: 400px;
  height: 400px;
  z-index: 10;
}
.ready .blue .name {
  position: absolute;
  right: 7%;
  top: 27%;
  width: 400px;
  height: 60px;
  font-size: 50px;
  color: #fff;
  z-index: 10;
}
.blue-enter {
  transform: translateX(100%);
}
.blue-enter-active {
  transition: all 1s ease;
}
.blue-enter-to {
  transform: translateX(0);
}
.blue-leave {
  transform: translateX(0);
}
.blue-leave-active {
  transition: all 3s ease;
}
.blue-leave-to {
  transform: translateX(100%);
}
.battle_info .redName {
  position: absolute;
  font-size: 22px;
  left: 300px;
  color: #fff;
  top: 55px;
}
.battle_info .blueName {
  position: absolute;
  font-size: 22px;
  right: 300px;
  color: #fff;
  top: 55px;
}
.battle_info .redAvatar {
  left: 40px;
  top: 30px;
  position: absolute;
  width: 78px;
  height: 78px;
  object-fit: cover;
}

.battle_info .blueAvatar {
  right: 40px;
  top: 30px;
  position: absolute;
  width: 78px;
  height: 78px;
  object-fit: cover;
}

.battle_info .current {
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 300px;
  height: 20px;
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
  z-index: 3;
}
.battle_info .current .split {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 18px;
}
.result {
  position: absolute;
  width: 1920px;
  height: 1080px;
  background: url("../assets/images/result.png") no-repeat;
  background-size: 100% 100%;
  color: #fff;
}
.wrap {
  position: absolute;
  top: 450px;
  /* top: 130px; */
  left: 380px;
  height: 540px;
  width: 1300px;
  margin: 0 auto;
  overflow: hidden;
}
.topFive {
  position: absolute;
  top: 130px;
  left: 380px;
  height: 270px;
  width: 1500px;
  margin: 0 auto;
  overflow: hidden;
}
.result ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
}
.result li,
.result a {
  display: block;
  height: 54px;
  line-height: 54px;
  font-size: 20px;
}
.result .name {
  position: absolute;
  width: 150px;
  left: 100px;
  text-align: center;
}
.result .win {
  position: absolute;
  width: 50px;
  left: 456px;
  text-align: center;
}
.result .lose {
  position: absolute;
  width: 50px;
  left: 673px;
  text-align: center;
}
.result .draw {
  position: absolute;
  width: 50px;
  left: 890px;
  text-align: center;
}
.result .total {
  position: absolute;
  width: 50px;
  left: 1145px;
  text-align: center;
}
.result .index{
  position: absolute;
  width: 50px;
  left: 0px;
  text-align: left;
}
</style>